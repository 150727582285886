import React from "react"
import { graphql } from "gatsby"
import Panel from "../components/panel"
import Card from "../components/card"
import Banner from "../components/banner"
import Engpro from "../components/engpro"
import CarolAssistant from "../components/carolAssistant/avatar"
import GoogleAnalytics from "../components/analytics"
import GoogleTagManagerHeader from "../components/tagManagerHeader"
import GoogleTagManagerBody from "../components/tagManagerBody"
import PNButton from "../components/pn-button"
import ThemeChanger from "../components/themeChanger"
import { Carousel } from "react-responsive-carousel"
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || "";
    return imgPath || '';
}

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

const checkValidDate = (item) => {
    let expDateString = item.frontmatter.expirationDate;
    if (!expDateString) return true;
    let expDate = new Date(expDateString);
    let datenow = new Date(Date.now());
    const diffTime = datenow - expDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 1;
}

export default ({ pageContext, data }) => {
    const banner = data.banner.nodes[0];
    const country = banner.fileAbsolutePath.split('localized/')[1].split('/')[0]
    const language = banner.fileAbsolutePath.split('localized/')[1].split('/')[1]
    
    let readMore;
    let newsHeader;
    let pnbuttonText;
    if (language === 'por') {
        readMore = 'Saiba mais';
        newsHeader = 'Comunicados Importantes'
        pnbuttonText = 'Clique aqui para conferir as novidades das rotinas'
    } else if (language === 'eng') {
        readMore = 'Read more';
        newsHeader = 'Important News'
        pnbuttonText = 'Click here to check the program news'
    } else if (language === 'spa') {
        readMore = 'Sepa mas';
        newsHeader = 'Anuncios Importantes'
        pnbuttonText = 'Haga clic aquí para ver las noticias sobre las rutinas'
    }
    
    const pnUrl = `https://totvsnews.engpro.totvs.com.br/${pageContext.moduleName}/${pageContext.country}/${pageContext.language}/images/${pageContext.moduleName}`;

    const cards = data.cards.nodes;
    const standardCards = data.standardCards.nodes;
    const links = data.links.nodes;
    const news = data.news.nodes;

    const engproConfig = data.engproConfig.nodes[0];
    const engproBannerFilters = data.engproBannerFilters.nodes[0];
    let moduleName;
    if (engproBannerFilters) moduleName = engproBannerFilters.frontmatter.name;
    const engproBanner = data.engproBanner.nodes;

    let bannerFilter = engproBanner.filter(engproItem => {
        return (engproItem.frontmatter.showin && engproItem.frontmatter.showin.filter(x => x === moduleName).length > 0) || (engproItem.frontmatter.showin === null || (engproItem.frontmatter.showin && engproItem.frontmatter.showin.length === 0));
    });

    //Getting all assets from markdown
    const assetBanner = data.assetBanner.nodes[0];
    const assetHeader = data.assetHeader.nodes[0];
    const assetCardBlue = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-blue')[0];
    const assetCardGray = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-gray')[0];
    const assetCardOrange = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-orange')[0];
    const assetCardBlueSmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-blue-small')[0];
    const assetCardGraySmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-gray-small')[0];
    const assetCardOrangeSmall = data.assetCards.nodes.filter(x => x.frontmatter.type === 'card-orange-small')[0];
    const assetPNHeader = data.assetPNHeader.nodes[0];

    //Getting all asset img paths
    const bannerImg = getResizedImg(assetBanner, 1024);
    const headerImg = getResizedImg(assetHeader, 1024);
    const cardBlueImg = getResizedImg(assetCardBlue, 320);
    const cardGrayImg = getResizedImg(assetCardGray, 320);
    const cardOrangeImg = getResizedImg(assetCardOrange, 320);
    const cardBlueImgSmall = getResizedImg(assetCardBlueSmall, 320);
    const cardGrayImgSmall = getResizedImg(assetCardGraySmall, 320);
    const cardOrangeImgSmall = getResizedImg(assetCardOrangeSmall, 320);
    const assetPNHeaderImgSmall = getResizedImg(assetPNHeader, 1024);

    //Getting all card content from markdown (standard)
    const standardCardTopBlue = standardCards.filter(x => x.frontmatter.type === 'card-top-blue')[0];
    const standardCardTopGray = standardCards.filter(x => x.frontmatter.type === 'card-top-gray')[0];
    const standardCardTopOrange = standardCards.filter(x => x.frontmatter.type === 'card-top-orange')[0];
    const standardCardBottomBlue = standardCards.filter(x => x.frontmatter.type === 'card-bottom-blue')[0];
    const standardCardBottomGray = standardCards.filter(x => x.frontmatter.type === 'card-bottom-gray')[0];
    const standardCardBottomOrange = standardCards.filter(x => x.frontmatter.type === 'card-bottom-orange')[0];

    //Getting all card content from markdown (localized)
    const cardTopBlue = cards.filter(x => x.frontmatter.type === 'card-top-blue')[0];
    const cardTopGray = cards.filter(x => x.frontmatter.type === 'card-top-gray')[0];
    const cardTopOrange = cards.filter(x => x.frontmatter.type === 'card-top-orange')[0];
    const cardBottomBlue = cards.filter(x => x.frontmatter.type === 'card-bottom-blue')[0];
    const cardBottomGray = cards.filter(x => x.frontmatter.type === 'card-bottom-gray')[0];
    const cardBottomOrange = cards.filter(x => x.frontmatter.type === 'card-bottom-orange')[0];

    const topCards = [cardTopBlue, cardTopGray, cardTopOrange].filter(item => item !== undefined);
    const bottomCards = [cardBottomBlue, cardBottomGray, cardBottomOrange].filter(item => item !== undefined);;
    const standardTopCards = [standardCardTopBlue, standardCardTopGray, standardCardTopOrange].filter(item => item !== undefined);;
    const standardBottomCards = [standardCardBottomBlue, standardCardBottomGray, standardCardBottomOrange].filter(item => item !== undefined);;

    const groupedLinks = Array.from(groupBy(links, (item) => {
        if (item.frontmatter.category) return item.frontmatter.category
        else return ''
    }));
    //groupedLinks.sort(compareObjGroups);

    const horizontalContainerConditionalJustifyTop = (cardTopBlue && cardTopGray && cardTopOrange) ? { "justify-content": "space-between" } : { "justify-content": "space-around" };
    const horizontalContainerConditionalJustifyBottom = (cardBottomBlue && cardBottomGray && cardBottomOrange) ? { "justify-content": "space-between" } : { "justify-content": "space-around" };

    return (
        <div className="main">
            <title>{`${pageContext.moduleName}-${country}-${language}`}</title>
            <GoogleTagManagerHeader />
            <GoogleTagManagerBody />
            <ThemeChanger />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-K14SPPV5EN"></script>
            <GoogleAnalytics module={pageContext.moduleName} />
            <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
            {banner && <Banner title={banner.frontmatter.title} imgPath={bannerImg}>
                {banner.frontmatter.text}
            </Banner>}
            <PNButton imgPath={assetPNHeaderImgSmall} text={pnbuttonText} url={pnUrl}/>
            {standardTopCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyTop}>
                {standardCardTopGray && <Card title={standardCardTopGray.frontmatter.title} color="gray" link={standardCardTopGray.frontmatter.link} imgPath={cardGrayImgSmall} cardTop={true}></Card>}
                {standardCardTopBlue && <Card title={standardCardTopBlue.frontmatter.title} color="blue" link={standardCardTopBlue.frontmatter.link} imgPath={cardBlueImgSmall} cardTop={true}></Card>}
                {standardCardTopOrange && <Card title={standardCardTopOrange.frontmatter.title} color="orange" link={standardCardTopOrange.frontmatter.link} imgPath={cardOrangeImgSmall} cardTop={true}></Card>}
            </div>}
            {topCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyTop}>
                {cardTopGray && <Card title={cardTopGray.frontmatter.title} color="gray" link={cardTopGray.frontmatter.link} imgPath={cardGrayImgSmall} cardTop={true}></Card>}
                {cardTopBlue && <Card title={cardTopBlue.frontmatter.title} color="blue" link={cardTopBlue.frontmatter.link} imgPath={cardBlueImgSmall} cardTop={true}></Card>}
                {cardTopOrange && <Card title={cardTopOrange.frontmatter.title} color="orange" link={cardTopOrange.frontmatter.link} imgPath={cardOrangeImgSmall} cardTop={true}></Card>}
            </div>}
            {
                !engproConfig.frontmatter.disable &&
                <div className="engproArea" style={{"margin": "10px 0px"}}>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop={true}
                        showIndicators={true}
                        showStatus={false}
                        autoPlay={true}
                        interval={5000}
                    >
                        {bannerFilter.filter(engproItem => checkValidDate(engproItem)).map(engproItem => {
                            let engproBannerImg = (engproBanner !== undefined) ? getResizedImg(engproItem, engproItem.frontmatter.size) : undefined;
                            return (
                                <Engpro imgPath={engproBannerImg} title={engproItem.frontmatter.title} link={engproItem.frontmatter.link} width={engproItem.frontmatter.size} height={engproItem.frontmatter.height || "200"}>
                                    <div dangerouslySetInnerHTML={{ __html: engproItem.html }}></div>
                                </Engpro>
                            )
                        })}
                    </Carousel>
                </div>
            }
            {news.length > 0 && <Panel title={newsHeader} className="news" imgPath={headerImg}>
                <ul>
                    {news.map(newsItem => (
                        <li key={`key${news.indexOf(newsItem)}`}>
                            <div>
                                <b>{newsItem.frontmatter.title}</b>
                                <p>{newsItem.frontmatter.text}
                                    {newsItem.frontmatter.link && <a target="_placeholder" rel="noopener noreferrer" href={newsItem.frontmatter.link}>
                                        <u>{readMore}</u>
                                        <i className="material-icons">link</i>
                                    </a>}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </Panel>}
            {standardBottomCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyBottom}>
                {standardCardBottomGray && <Card title={standardCardBottomGray.frontmatter.title} color="gray" link={standardCardBottomGray.frontmatter.link} imgPath={cardGrayImg}>
                    <p>{standardCardBottomGray.frontmatter.text}</p>
                </Card>}
                {standardCardBottomBlue && <Card title={standardCardBottomBlue.frontmatter.title} color="blue" link={standardCardBottomBlue.frontmatter.link} imgPath={cardBlueImg}>
                    <p>{standardCardBottomBlue.frontmatter.text}</p>
                </Card>}
                {standardCardBottomOrange && <Card title={standardCardBottomOrange.frontmatter.title} color="orange" link={standardCardBottomOrange.frontmatter.link} imgPath={cardOrangeImg}>
                    <p>{standardCardBottomOrange.frontmatter.text}</p>
                </Card>}
            </div>}
            {bottomCards.length > 0 && <div className="horizontal-container" style={horizontalContainerConditionalJustifyBottom}>
                {cardBottomGray && <Card title={cardBottomGray.frontmatter.title} color="gray" link={cardBottomGray.frontmatter.link} imgPath={cardGrayImg}>
                    <p>{cardBottomGray.frontmatter.text}</p>
                </Card>}
                {cardBottomBlue && <Card title={cardBottomBlue.frontmatter.title} color="blue" link={cardBottomBlue.frontmatter.link} imgPath={cardBlueImg}>
                    <p>{cardBottomBlue.frontmatter.text}</p>
                </Card>}
                {cardBottomOrange && <Card title={cardBottomOrange.frontmatter.title} color="orange" link={cardBottomOrange.frontmatter.link} imgPath={cardOrangeImg}>
                    <p>{cardBottomOrange.frontmatter.text}</p>
                </Card>}
            </div>}
            {links.length > 0 && <Panel title="Links" className="links" imgPath={headerImg}>
                {groupedLinks.length > 0 && groupedLinks.map(group => {
                    if (group[0] !== '') {
                        return (
                            <div key={`key${getRandomInt(0, 10000)}`}>
                                <b>{group[0]}</b>
                                <ul>
                                    {group[1].map(link => (
                                        <li key={`key${getRandomInt(0, 10000)}`}>
                                            {link.frontmatter.text}
                                            {link.frontmatter.link && <a target="_placeholder" rel="noopener noreferrer" href={link.frontmatter.link}>
                                                <u>{readMore}</u>
                                                <i className="material-icons">link</i>
                                            </a>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    } else {
                        return (
                            <ul>
                                {group[1].map(link => (
                                    <li key={`key${getRandomInt(0, 10000)}`}>
                                        {link.frontmatter.text}
                                        <a target="_placeholder" rel="noopener noreferrer" href={link.frontmatter.link}>
                                            <u>{readMore}</u>
                                            <i className="material-icons">link</i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                }
                )}</Panel>}
            <CarolAssistant></CarolAssistant>
        </div>
    )
}

export const query = graphql`
query ($parentPath: String!, $standardPath: String!, $parentAndStandardPath: String!, $languageRegex: String!, $moduleNameRegex: String!, $countryRegex: String!) {
    engproConfig: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro-config/"}}) {
        nodes {
            html
            excerpt
            frontmatter {
                blocks
                disable
            }
        }
    }

    engproBannerFilters: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/module-filters/"}, frontmatter: { name: {regex: $moduleNameRegex}}}) {
        nodes {
            html
            excerpt
            frontmatter {
                name
            }
        }
    }

    engproBanner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/engpro/"}, frontmatter: { type: {regex: "/banner/"}, country: {regex: $countryRegex}, language: {regex: $languageRegex}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
            html
            excerpt
            frontmatter {
                title
                type
                size
                height
                link
                language
                country
                showin
                expirationDate
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetCountdown: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "countdown"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 320, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetBanner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "banner"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "panel-header"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

    banner: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $parentPath}, frontmatter: { type: {eq: "banner"}}} ) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
            }
            fileAbsolutePath  
        }
    },
    cards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $parentPath}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
              link
            }  
        }
    },
    standardCards: allMarkdownRemark(filter: { fileAbsolutePath: {regex: $standardPath}, frontmatter: { type: {regex: "/card/"}}}) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              type
              text
              link
            }  
        }
    },
    links: allMarkdownRemark(filter: {fileAbsolutePath: {regex: $parentAndStandardPath}, frontmatter: { type: {regex: "/link/"}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes{
            html
            excerpt
            frontmatter {
              link
              type
              text
              link
              category
              date
            }  
        }
    },
    news: allMarkdownRemark(filter: {fileAbsolutePath: {regex: $parentAndStandardPath}, frontmatter: { type: {eq: "news"}}}, sort: { fields: frontmatter___date, order: DESC }) {
        nodes{
            html
            excerpt
            frontmatter {
              title
              text
              type
              link
              date
            }  
        }
    },

    assetPNHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "pn-panel"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }

  }
`